import {AUTHENTICATED_SUCCESS,AUTHENTICATED_FAIL,LOGOUT,LOADING_FALSE,LOADING_TRUE } from '../actions/types';

const initialState = {
    isAuthenticated: false, // Correct spelling here
    isLoading: false
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state, 
                isAuthenticated: true // Correct spelling here
            };
        case AUTHENTICATED_FAIL:
            return {
                ...state, 
                isAuthenticated: false // Correct spelling here
            };
        case LOGOUT:
            return {
                ...state, 
                isAuthenticated: false // Correct spelling here
            };
        case LOADING_FALSE:
            return {
                ...state, 
                isLoading: false // Correct spelling here
            };
        case LOADING_TRUE:
            return {
                ...state, 
                isLoading: true // Correct spelling here
            };
        default:
            return state;
    }

}
