import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../containers/Loading';
import { login, logout } from '../actions/auth';
import { getCookie, doesCookieExist } from '../utils/cookies';
import { connect } from 'react-redux';

const Navbar = ({ isAuthenticated, isLoading, login, logout, profile, userName }) => {
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogin = () => {
    const loginUrl = "https://login.ruturbo.com/login?client_id=4kdc29ckmri961e9of977i6s14&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fapi.ruturbo.com%2Fcallback";
    window.location.href = loginUrl;
  };

  const handleLogin2 = async () => {
    await login(navigate);
    try {
      setSessionId(getCookie("session_id"));
      setUserData(userName);
    } catch (err) {
      console.log("session not there", err);
    }
  };

  const handleLogout = async () => {
    await logout(navigate);
    setSessionId(null);
    setUserData(null);
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
      {isLoading ? (
        <Loading loading={isLoading} color="#123abc" />
      ) : (
        <Fragment>
          <h1 className="w-full text-3xl font-bold">RU TURBO</h1>
          <button onClick={handleToggleMenu} className="md:hidden text-3xl">
            ☰
          </button>
          <ul className={`md:flex ${isMenuOpen ? 'flex' : 'hidden'} absolute md:static top-20 left-0 bg-gray-800 md:bg-transparent w-full md:w-auto`}>
            {isAuthenticated ? (
              <Fragment>
                <Link to="/profile" className="p-4">Profile</Link>
                <Link to="/dashboard" className="p-4">Dashboard</Link>
                <span className="p-4">{profile?.UserName}</span>
                <div className="flex justify-center items-center w-full md:w-auto">
                  <button
                    className="p-4 bg-red-500 text-white rounded-xl w-[70px] text-center"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <Link to="/" className="p-4">Home</Link>
                <button className="p-4">About</button>
                <button className="p-4">Contact</button>
                <button
                  className="p-4 bg-red-500 text-white rounded-xl w-[70px]"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </Fragment>
            )}
          </ul>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  profile: state.auth.profile,
  userName: state.profile.userName
});

export default connect(mapStateToProps, { login, logout })(Navbar);
