// utils/cookies.js
import Cookies from 'js-cookie';

// Set a cookie
export const setCookie = (name, value, options = {}) => {
    Cookies.set(name, value, options);
};

// Get a cookie
export const getCookie = (name) => {
    return Cookies.get(name);
};

// Remove a cookie
export const removeCookie = (name) => {
    Cookies.remove(name);
};

// Clear all cookies
export const clearAllCookies = () =>{
    // Get all cookies
    const allCookies = Cookies.get();
  
    // Iterate over all cookies and remove them
    for (let cookieName in allCookies) {
      Cookies.remove(cookieName, { path: '/' });
    }
  }


export const doesCookieExist =(name)=> {
// Get the cookie value using js-cookie
const cookieValue = Cookies.get(name);

// Check if the cookie value is undefined
return cookieValue !== undefined;
}