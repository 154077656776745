import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../index.css';

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedCourses, setExpandedCourses] = useState({});
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const title = query.get('title');

  const dayMap = {
    'M': 'Monday',
    'T': 'Tuesday',
    'W': 'Wednesday',
    'H': 'Thursday',
    'F': 'Friday',
    'S': 'Saturday',
    'U': 'Sunday'
  };

  const parseSections = (sections) => {
    try {
      const cleanedSections = sections
        .replace(/'/g, '"') // Convert single quotes to double quotes for JSON parsing
        .replace(/\b(\w+)\s*:/g, '"$1":'); // Add quotes around keys
      return JSON.parse(cleanedSections);
    } catch (parseError) {
      console.error('Failed to parse sections:', parseError);
      return [];
    }
  };

  const formatTime = (time) => {
    if (!time) return 'N/A';
    let hours = parseInt(time.slice(0, 2), 10);
    const minutes = time.slice(2);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const getFullDayName = (abbreviation) => {
    return abbreviation
      .split('')
      .map((day) => dayMap[day] || day)
      .join(', ');
  };

  useEffect(() => {
    const fetchResults = async () => {
      if (!title) return;
      try {
        const response = await axios.get(`https://api.ruturbo.com/search?title=${encodeURIComponent(title)}`);
        setResults(response.data.results);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [title]);

  const toggleCourse = (index) => {
    setExpandedCourses((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (loading) return <p style={{ color: 'white' }}>Loading...</p>;
  if (error) return <p style={{ color: 'white' }}>{error}</p>;

  return (
    <div className="search-results">
      <h2 style={{ color: 'white' }}>Search Results for "{title}"</h2>
      {results.length > 0 ? (
        <ul className="course-list">
          {results.map((result, index) => {
            const parsedSections = parseSections(result.sections);

            return (
              <li key={index} className="course-item">
                <div className="course-header" onClick={() => toggleCourse(index)}>
                  <div className="course-info">
                    <span className="course-code">{result.courseString}</span>
                    <span className="course-title">{result.title}</span>
                    <span className="course-credits">{result.credits} cr.</span>
                    <span className="course-status">Sections: {parsedSections.length}</span>
                  </div>
                  <button className="expand-toggle">{expandedCourses[index] ? '-' : '+'}</button>
                </div>

                {expandedCourses[index] && (
                  <div className="course-details">
                    <h4 style={{ color: 'white' }}>Sections:</h4>
                    {parsedSections.map((section, secIndex) => (
                      <div key={secIndex} className="section-item">
                        <div className="section-header">
                          <span className="section-number">Section {section.number} (Index: {section.index})</span>
                          <span className={`section-status ${section.status ? section.status.toLowerCase() : ''}`}>
                            {section.status || 'Status Unknown'}
                          </span>
                        </div>
                        <div className="professor" style={{ color: 'white', marginBottom: '8px' }}>
                          <strong>Professor:</strong> {section.instructorsText || 'N/A'}
                        </div>
                        <div className="meeting-times">
                          <table className="meeting-table">
                            <thead>
                              <tr>
                                <th>Day</th>
                                <th>Time</th>
                                <th>Location</th>
                                <th>Campus</th>
                              </tr>
                            </thead>
                            <tbody>
                              {section.meetingTimes.map((time, timeIndex) => (
                                <tr key={timeIndex}>
                                  <td>{getFullDayName(time.Meetingdays) || 'N/A'}</td>
                                  <td>{time.StartTime ? `${formatTime(time.StartTime)} - ${formatTime(time.EndTime)}` : 'N/A'}</td>
                                  <td>{time.buildingCode && time.roomnumber ? `${time.buildingCode} ${time.roomnumber}` : 'N/A'}</td>
                                  <td>{time.Campus || 'N/A'}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <p style={{ color: 'white' }}>No results found.</p>
      )}
    </div>
  );
};

export default SearchResults;
