import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getCookie } from '../utils/cookies';
import Loading from '../containers/Loading'; // Import your loading component
import { connect } from 'react-redux';

function Profile({ userName, year, telegram, phone, membership, email, discord }) {  // Destructure props here
  const [userInfo, setUserInfo] = useState(null); // State to store the user data
  const [loading, setLoading] = useState(true);   // Loading state
  const [error, setError] = useState(null);       // Error state

  // Commented out function for fetching user info
  // useEffect(() => {
  //   const getinfo = async () => {
  //     const session_id = getCookie("session_id"); // Fetch session ID from cookies
  //     console.log("Session ID" , session_id)
  //     const config = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': session_id
  //       },
  //       withCredentials: true,
  //     };

  //     try {
  //       const res = await axios.get("https://api.ruturbo.com/getinfo", config); // Fetch data
  //       console.log('API Response:', res.data.Email); // Log the successful response

  //       setUserInfo(res.data); // Update state with user data
  //       setLoading(false);     // Set loading to false
  //     } catch (err) {
  //       console.error('Error fetching user data:', err.response || err.message); // Log the error
  //       setError('Failed to fetch user info'); // Handle error
  //       setLoading(false);                     // Stop loading
  //     }
  //   };
  //   getinfo(); // Fetch user info when component mounts
  // }, []);

  // if (loading) {
  //   return <Loading />; // Show loading message
  // }

  if (error) {
    return <p className='text-white'>{error}</p>; // Show error message
  }

  return (
    <div className='text-white'>
      <h2>User Info</h2>
      <p><strong>UserName:</strong> {userName}</p> {/* Access userName from props */}
      <p><strong>Year:</strong> {year}</p>         {/* Access year from props */}
      <p><strong>Telegram:</strong> {telegram}</p> {/* Access telegram from props */}
      <p><strong>Phone:</strong> {phone}</p>       {/* Access phone from props */}
      <p><strong>Membership:</strong> {membership}</p> {/* Access membership from props */}
      <p><strong>Email:</strong> {email}</p>       {/* Access email from props */}
      <p><strong>Discord:</strong> {discord}</p>   {/* Access discord from props */}
    </div>
  );
};

const mapStatetoProps = (state) => ({
  userName: state.profile.userName, 
  year: state.profile.year, 
  telegram: state.profile.telegram, 
  phone: state.profile.phone,
  membership: state.profile.membership, 
  email: state.profile.email, 
  discord: state.profile.discord
});

export default connect(mapStatetoProps, {})(Profile);
