import React, { useEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkAuthStatus } from '../actions/auth';
import Navbar from '../components/Navbar';
import Loading from '../containers/Loading';

const Layout = ({ isAuthenticated, isLoading, checkAuthStatus, userName }) => {
    const navigate = useNavigate();

    useEffect(() => {
        // Only run checkAuthStatus once on mount
        
          checkAuthStatus(navigate);
        
    }, [checkAuthStatus, navigate]);

    // Show loading screen while authentication status is being determined
    if (isLoading) {
        return <Loading />;
    }

    // Redirect to login if not authenticated
    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    

    // Render authenticated layout with Navbar and nested routes
    return (
        <div>
            <Navbar />
            <main>
                <Outlet />  {/* Nested routes will be rendered here */}
            </main>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
    userName: state.profile.userName
});

const mapDispatchToProps = {
    checkAuthStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
