import {
    APPEND_INDEX,
    REMOVE_INDEX
    
} from '../actions/types';



const initialState = {
   SniperIndexes: []

};

export default function(state = initialState, action){
    const { type, payload } = action;
   
    switch(type){
        case APPEND_INDEX:
            return{
                ...state, 
                SniperIndexes: [...state.SniperIndexes, action.payload]
            }
        case REMOVE_INDEX:
            console.log("NUMBER (action.payload):", action.payload);
            console.log("STATE REMOVING INDEX:", action.payload);
            console.log("Type of action.payload:", typeof action.payload);
            
            console.log("OLD LIST:", state.SniperIndexes);

            state.SniperIndexes.forEach((obj, index) => {
                console.log(`SniperIndexes[${index}] - index:`, obj.index);
                console.log(`Type of SniperIndexes[${index}].index:`, typeof obj.index);
            });

            // Use Array.includes to match if `index` is an array
            const list = state.SniperIndexes.filter(obj => {
                const match = Array.isArray(obj.index) ? !obj.index.includes(action.payload) : obj.index !== action.payload;
                console.log(`Comparing obj.index (${obj.index}) to action.payload (${action.payload}):`, match);
                return match;
            });
            
            console.log("NEW LIST (After Filter):", list);
            
            return {
                ...state,
                SniperIndexes: list,
            };

                        

        default:
            return state;
    }
}
