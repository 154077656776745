import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Home from './containers/Home';
import Data from './containers/Classes_Data';
import Profile from './containers/Profile';
import Dashboard from './containers/Dashboard';
import SearchResults from './containers/SearchResults'; // Import the SearchResults component
import Layout from './hocs/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import { connect } from 'react-redux';

const App = ({ isAuthenticated }) => {
  return (
    <Router>
      <Routes>
        {/* Public route - Home */}
        <Route
          path="/"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Home />
            </PublicRoute>
          }
        />

        {/* Layout route to wrap authenticated routes */}
        <Route path="/" element={<Layout />}>
          {/* Protected routes for authenticated users */}
          <Route
            path="/data"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Data />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <SearchResults />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
