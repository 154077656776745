import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { appendIndex, removeIndex, threads } from '../actions/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Dashboard = ({ SniperIndexes, appendIndex, removeIndex, threads }) => {
  const [courseSearchValue, setCourseSearchValue] = useState(''); // State for course search input
  const [numberInputValue, setNumberInputValue] = useState(''); // State for 5-digit number input
  const [suggestions, setSuggestions] = useState([]);
  const [threadsData, setThreadsData] = useState(null);
  const navigate = useNavigate();

  // Fetch threads data on component mount
  useEffect(() => {
    const fetchThreads = async () => {
      const data = await threads();
      setThreadsData(data);
    };
    fetchThreads();
  }, [threads]);

  // Fetch autocomplete suggestions for course search
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (courseSearchValue.trim() === '') {
        setSuggestions([]);
        return;
      }
      try {
        const response = await axios.get(
          `https://api.ruturbo.com/autocomplete?title=${encodeURIComponent(courseSearchValue)}`
        );
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };
    fetchSuggestions();
  }, [courseSearchValue]);

  const handleCourseSearchChange = (e) => {
    setCourseSearchValue(e.target.value);
  };

  const handleNumberInputChange = (e) => {
    setNumberInputValue(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setCourseSearchValue(suggestion);
    setSuggestions([]);
    // Redirect to search results page with the selected suggestion
    navigate(`/search?title=${encodeURIComponent(suggestion)}`);
  };

  const handleCourseSearchSubmit = (e) => {
    e.preventDefault();
    if (courseSearchValue.trim() === '') return;
    // Redirect to search results page with the course search value
    navigate(`/search?title=${encodeURIComponent(courseSearchValue)}`);
  };

  const handleNumberSubmit = (e) => {
    e.preventDefault();
    const numArray = numberInputValue.split(',').map((num) => num.trim());
    const isValidNumList = numArray.every((num) => /^\d{5}$/.test(num));

    if (!isValidNumList) {
      alert('Please enter valid 5-digit numbers separated by commas.');
      return;
    }

    appendIndex(numArray);
    setNumberInputValue('');
  };

  const handleDelete = (indexToDelete) => {
    removeIndex(indexToDelete);
  };

  return (
    <div>
      <h2 className="text-white">Dashboard</h2>

      {/* Search Bar with Autocomplete */}
      <form onSubmit={handleCourseSearchSubmit}>
        <input
          type="text"
          placeholder="Search for courses"
          value={courseSearchValue}
          onChange={handleCourseSearchChange}
          className="text-black"
        />
        <button type="submit" className="text-white">
          Search
        </button>
      </form>
      {suggestions.length > 0 && (
        <ul className="autocomplete-suggestions">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="text-white cursor-pointer"
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}

      {/* Existing 5-digit Number Submission */}
      <form onSubmit={handleNumberSubmit}>
        <input
          type="text"
          placeholder="Enter 5-digit numbers separated by commas"
          value={numberInputValue}
          onChange={handleNumberInputChange}
          className="text-black"
        />
        <button type="submit" className="text-white">
          Submit Numbers
        </button>
      </form>

      <div className="text-white">
        <h3>Submitted Numbers</h3>
        <ul>
          {SniperIndexes.map((item, idx) => (
            <li key={idx}>
              Index: {item.index}, Times: {item.times ? item.times.join(', ') : ''}
              <button
                onClick={() => handleDelete(item.index)}
                className="text-red-500 ml-4"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="text-white">
        <h3>Threads Data</h3>
        {threadsData !== null ? (
          <p>Threads: {threadsData}</p>
        ) : (
          <p>Loading threads data...</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  SniperIndexes: state.index.SniperIndexes,
});

export default connect(mapStateToProps, { appendIndex, removeIndex, threads })(Dashboard);
