import React from 'react'
import Data_menu from '../components/DataMenu'
import Navbar from '../components/Navbar'
function Classes_Data() {
  return (
    <div>
      <Data_menu></Data_menu>
    </div>
  )
}

export default Classes_Data
