import React from 'react';
import ReactDOM from 'react-dom/client';  // Import from 'react-dom/client'
import { Provider } from 'react-redux';
import store from './store';
import App from './App';

// Create the root element using createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component within the root
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
