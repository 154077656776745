import { setCookie, getCookie, clearAllCookies, doesCookieExist, removeCookie } from '../utils/cookies';

import axios from 'axios';
import {
    LOADING_TRUE,
    LOADING_FALSE,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    LOGIN_PROFILE,
    LOGOUT,
    APPEND_INDEX,
    LOGOUT_PROFILE,
    REMOVE_INDEX,
    RESET_STATE
} from './types';

// Login Action
export const login = (navigate) => async (dispatch) => {
    // Start loading
    // dispatch({ type: LOADING_TRUE });

    try {
        // Set a session ID (replace with actual login API call in production)
        setCookie("session_id", "fceba09b-fe17-49da-bb34-4b7328eae004", { expires: 24 });

        // Dispatch successful authentication
        dispatch({ type: AUTHENTICATED_SUCCESS });

        // Navigate to dashboard after successful login
        window.location.reload();
        navigate("/dashboard");
    } catch (err) {
        console.error('Error during login:', err);
        // dispatch({ type: AUTHENTICATED_FAIL });
    } 
};


// Logout Action
export const logout = (navigate) => async (dispatch) => {
    // Start loading
    dispatch({ type: LOADING_TRUE });

    try {
        // Clear the session cookie
        // removeCookie("session_id");
        document.cookie = "session_id=; path=/; domain=ruturbo.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=None";


        // Dispatch logout actions to update Redux state
        dispatch({ type: LOGOUT_PROFILE });
        dispatch({ type: LOGOUT });
        dispatch({type:RESET_STATE})

        // Add delay to keep the loading screen visible before navigating
        setTimeout(() => {
            // Navigate to the home page after logout
            navigate("/");

            // End loading once navigation is complete
            dispatch({ type: LOADING_FALSE });
        }, 500); // 10-second delay for the loading screen
        localStorage.clear();
    } catch (err) {
        console.error("Error during logout:", err);
        // Dispatch LOADING_FALSE in case of error to stop loading
        dispatch({ type: LOADING_FALSE });
        localStorage.clear();
    }
};




// Append Index Action
export const appendIndex = (numArray) => async (dispatch, getState) => {
    const currentState = getState();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': getCookie("session_id")
        }
    };

    const body = JSON.stringify({
        email: currentState.profile.email,
        membership: currentState.profile.membership,
        index: numArray,
        phoneHooks: {
            phone: currentState.profile.phone,
            discord: currentState.profile.discord,
            telegram: currentState.profile.telegram
        }
    });

    try {
        // Call the API first
        const response = await axios.post('https://api.ruturbo.com/index', body, config);
        console.log("AppendIndex API Response:", response.data);

        if (response.status === 200) {
            // If API call succeeds, update the Redux state with the indexes
            numArray.forEach(num => {
                dispatch({ type: APPEND_INDEX, payload: { index: num, times: ["10am-11pm", "11pm-10am"] } });
            });
        }
    } catch (err) {
        console.error('Error in appendIndex:', err);
    }
};



export const removeIndex = (num) => async (dispatch, getState) => {
    console.log("Removing index from Redux immediately");
    
    // Dispatch Redux action to remove the item from the UI immediately
    dispatch({ type: REMOVE_INDEX, payload: num });

    const currentState = getState();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': getCookie("session_id")
        }
    };
    const body = JSON.stringify({
        email: currentState.profile.email,
        membership: currentState.profile.membership,
        index: [num] // pass the index as an array
    });

    try {
        console.log("Calling delete API");
        const response = await axios.post('https://api.ruturbo.com/deleteIndex', body, config);
        console.log("API Response:", response.data);
    } catch (err) {
        console.error('Error in RemoveIndex:', err);
        // Optional: Add a failure handler to re-add the item to the list if the API call fails
    }
};



export const checkAuthStatus = (navigate, shouldNavigate = true) => async (dispatch, getState) => {
    try {
        const userName = getState().profile.userName;
        const isAuthenticated = getState().auth.isAuthenticated;

        if (doesCookieExist("session_id") && userName == null) {
            dispatch({ type: LOADING_TRUE });
            const session_id = getCookie("session_id");

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': session_id
                },
                withCredentials: true,
            };

            console.log("CALLING API");

            const res = await axios.get("https://api.ruturbo.com/getinfo", config);
            console.log("DATA: ", res.data);

            dispatch({ type: AUTHENTICATED_SUCCESS });
            dispatch({ type: LOGIN_PROFILE, payload: res.data });

            // Extract and dispatch indexes
            const extractedIndexes = res.data.index || [];
            extractedIndexes.forEach((index) => {
                dispatch({
                    type: APPEND_INDEX,
                    payload: { index, times: ["10am-11pm", "11pm-10am"] }
                });
            });

            if (shouldNavigate) {
                setTimeout(() => {
                    navigate("/dashboard");
                }, 1000);
            }

            dispatch({ type: LOADING_FALSE });
            return { session_id: getCookie("session_id"), userData: res.data };
        } else if (!doesCookieExist("session_id")) {
            dispatch({ type: LOGOUT });
            dispatch({ type: LOGOUT_PROFILE });
            localStorage.clear();
            navigate("/");
        }
    } catch (err) {
        console.error('Error during auth status check:', err);
        dispatch({ type: AUTHENTICATED_FAIL });
        localStorage.clear();
    }
};



export const threads = () => async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "adminUser123"
        },
        withCredentials: true,
    };

    try { 
        const res = await axios.get("https://api.ruturbo.com/threads", config);
        console.log("threads: ", res.data.ec2_response.Threads);
        return res.data.ec2_response.Threads; // Return just the Threads value
    } catch (err) {
        console.error('Error during getting threads:', err);
        return null;  // Return null in case of error to avoid rendering issues
    }
};

