import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer';
import { loadState, saveState } from './utils/localStorage'; // Import helper functions

// Load the persisted state from localStorage (if any)
const persistedState = loadState();

const store = configureStore({
    reducer: rootReducer,
    preloadedState: persistedState,  // Pass the loaded state into the store
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,  // Disable serializable state checks for certain cases
        }),
    devTools: process.env.NODE_ENV !== 'production',  // Enable Redux dev tools only in development
});

// Subscribe to store updates and save the state to localStorage
store.subscribe(() => {
    saveState(store.getState());  // Save the entire state to localStorage
});

export default store;
