import React from "react";
import ClipLoader from "react-spinners/HashLoader";
import "../index.css"
// const override: React.CSSProperties = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };
function Loading() {
  return (
    <div className="loader mx-auto text-center flex flex-col justify-center">
    <ClipLoader
        size={60}
        color={"#e45638"}
        // loading={this.state.loading}
        speedMultiplier={1.8}
        aria-label="Loading Spinner"
        data-testid="loader"
    />
    {/* <p className="text-white "></p> */}
  </div>
  )
}

export default Loading
