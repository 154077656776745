import {
    SET_USERNAME,
    SET_YEAR,
    SET_TELEGRAM,
    SET_PHONE,
    SET_MEMBERSHIP,
    SET_EMAIL,
    SET_DISCORD,
    LOGIN_PROFILE,
    LOGOUT_PROFILE,
    
    
}from '../actions/types';



const initialState = {
   userName: null, 
   year: null, 
   telegram: null, 
   phone: null, 
   membership: "FT",
   email:"example@example.com",
   discord: null

};

export default function(state = initialState, action){
    const { type, payload } = action;
   
    switch(type){
        case SET_USERNAME:
            return{
                ...state, 
                userName: payload
            }
        case SET_YEAR:
            return {
                ...state,
                year: payload
            };          
        case SET_TELEGRAM:
            return {
                ...state, 
                telegram: payload
            }
        case SET_PHONE:
            return {
                ...state,
                phone: payload
            };
        case SET_MEMBERSHIP:
            return {
                ...state, 
                membership: payload
            };

        case SET_EMAIL:
            return{
                ...state, 
                email: payload
            }
        case SET_DISCORD:
            return {
                ...state, 
                discord: payload
            };

        case LOGIN_PROFILE:
            return{
                ...state, 
                userName: payload.UserName,
                year: payload.year,
                telegram: payload.Telegram, 
                phone: payload.Phone, 
                membership: payload.Membership, 
                email: payload.Email, 
                discord: payload.Discord
            }
        
        case LOGOUT_PROFILE:
            return{
                ...state, 
                userName: null,
                year: null,
                telegram: null, 
                phone:null, 
                membership: null, 
                email: null, 
                discord: null
            }
        default:
            return state;
    }
}
