import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import SplineScene from '../components/SplineScene';
import Loading from '../components/Loading';

import axios from 'axios';

// import auth from '../reducers/auth';
// import '../styles.css'; // Ensure this imports your styles

function Home() {
  
  // const exchangeCodeForTokens = async (code) => {
  //   console.log("IN THE exchangeCodeForTokens");
    
  //   // Ensure these values are from your Cognito User Pool
  //   const url = `https://ruturbo.auth.us-east-1.amazoncognito.com/oauth2/token`;
  //   const params = new URLSearchParams();

  //   params.append('grant_type', "authorization_code");
  //   params.append('client_id', "4kdc29ckmri961e9of977i6s14"); // Replace with your Cognito App Client ID
  //   // params.append('client_secret', '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'); // Replace with your Cognito App Client Secret, if required
  //   params.append('code', code);
  //   params.append('redirect_uri', "http://localhost:3000"); // Ensure this matches exactly what is configured

  //   // Log parameters to verify they are correct
  //   for (const [key, value] of params.entries()) {
  //     console.log(`Parameter: ${key} = ${value}`);
  //   }
  //   console.log("URL", url,params)

  //   try {


  //     const response = await axios.post(url, params, {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //     });

  //     const { id_token, access_token, refresh_token } = response.data;
  //     console.log("TOKENS: ", access_token);
  //     setTokens({ id_token, access_token, refresh_token });
  //   } catch (error) {
  //     console.error('Error exchanging code for tokens:', error.response?.data || error.message);
  //     setError(error.response?.data || error.message);
  //   }
  // };

  return (
    <div>
      <Navbar />
      <Hero />
      <SplineScene />
      
    </div>
  );
}

export default Home;
