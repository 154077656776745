import { combineReducers } from '@reduxjs/toolkit';
import indexReducer from './indexReducer';
import profileReducer from './profileReducer';
import authReducers from './authReducers';
import { RESET_STATE } from '../actions/types';

// Wrap the combined reducers in a function to handle RESET_STATE
const appReducer = combineReducers({
    index: indexReducer,         // 'index' slice of the state
    profile: profileReducer,     // 'profile' slice of the state
    auth: authReducers           // 'auth' slice of the state
    // Add other reducers here
});

// Root reducer to intercept RESET_STATE action
const rootReducer = (state, action) => {
    if (action.type === RESET_STATE) {
        state = undefined;  // Resets state to initial values
    }
    return appReducer(state, action);
};

export default rootReducer;
